body {
  margin: 0;
  /* margin: 20px; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

footer {
  position: absolute;
  bottom: 2vh;
  font-size: 0.937rem;
  left: 5rem;
  text-align: center;
  z-index: 100;
}

.root {
  margin: 1rem 1rem 1rem 16rem;
  padding: 0.5rem 1.5rem;
}

.root_resize {
  margin-top: 4em;
  margin-left: 1em;
}

.root_box {
  margin: 2.4rem 0;
}

.topbar_resize {
  position: fixed !important;
  background-color: #ffffff !important;
  /* width: 100%; */
}

.toolbar_main {
  margin: 0.1rem 0 0 15.5rem;
  font-size: 1.12rem;
  font-weight: 500;
}

.toolbar_resize {
  margin-top: 0.2em;
  font-size: 1.1em;
}

.navigation {
  height: 3.7%;
  padding: 0 1.5rem;
  /* width: 100%; */
}

.inline_center {
  text-align: center;
}

.login_footer {
  text-align: center;
  margin-top: 44vh;
}

.flex {
  display: flex;
}

.flex_center {
  display: flex;
  align-items: center;
}

.flex_between {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.flex_end {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.flex_start {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.selected_tab {
  background-color: #9ca2c1 !important;
  color: #ffffff !important;
}

.selected_devtab {
  background-color: #455a64 !important;
}

.selected_row {
  background-color: #a4f1f9 !important;
}

.specified_row {
  color: #ea0d0d !important;
}

.tested_row {
  color: #00aa39 !important;
}

.holding_row {
  color: #6026ff !important;
}

.important_row {
  color: #0833f6 !important;
}

.not_important_row {
  color: #999999 !important;
}

.nopadding {
  padding: 0;
}

.whitecolor {
  color: #ffffff;
}

.request_tablehead {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
}

.request_head {
  color: #7787af !important;
  padding: 1em 0.5em !important;
}

.request_body {
  cursor: pointer !important;
  padding: 0.5em !important;
}

.request_tablecell {
  font-size: 0.9em !important;
}

.smallsize {
  font-size: 0.8rem;
}

.con {
  width: 3em !important;
  height: 3em !important;
}

.emptypage {
  min-height: 200px;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9ea2a9 !important;
  margin: 1.5rem 0;
}

.emptypage_icon {
  margin: 0.6rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
}

.bluecontent {
  color: #3663ff;
}

.click_group {
  border: 2px solid #00a2ff;
}

.drag_over {
  opacity: 0.1;
}

.nonedisplay {
  display: none;
}

.test {
  /* border: 1px solid darkorange; */
  /* border-radius: 50%; */
  /* padding: 0.2rem 0.5rem; */
  font-size: 1.5rem;
  /* background-color: blanchedalmond; */
  opacity: 0.7;
}

.drawing_stampinfo {
  padding: 5px;
  margin: 10px;
  background-color: #424242;
  border: black solid 1px;
  white-space: pre;
  border-radius: 4px;
  color: #ffffff;
}

.drawing_circleinfo {
  border: 1px solid darkgrey;
  border-radius: 12px;
  padding: 0.2rem 0.4rem;
  background-color: #ffffff;
}

.stamp_marker {
  border-radius: 14px;
  padding: 0.25rem;
  background: linear-gradient(#6c65ff 0%, #6c65ffa6 100%);
  color: #ffffff;
  font-weight: 600;
  z-index: 100;
  position: relative;
}

.stamp_marker:after {
  border-top: 6px solid#9786FF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  z-index: -2;
  top: 32px;
  left: 10.3px;
}

.stamp_info_marker {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.1rem;
  width: 2rem;
  background: linear-gradient(#6c65ff 0%, #6c65ffa6 100%);
  color: #ffffff;
  font-weight: 600;
  z-index: 100;
  position: relative;
}

.stamp_info_marker:after {
  border-top: 5px solid#9786FF;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 32px;
  left: 11.2px;
}

.stamp_info {
  display: inline-block;
  width: 1.2rem;
  height: 1.3rem;
  text-align: center;
  background-color: #ffffff;
  color: #615eff;
  border-radius: 50%;
  /* font-size: 0.7rem; */
}

.stamp_marker_index {
  display: inline-block;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  background: linear-gradient(#3038ff 0%, #9786ff 100%);
  color: #ffffff;
  font-weight: 600;
  font-size: 0.8rem;
  z-index: 100;
  position: relative;
}

.walkcourse_marker {
  border: 3px solid #ffffff;
  border-radius: 12px;
  padding: 0rem 0.4rem;
  background-color: #b3e5fc;
  color: rgb(38, 129, 255);
  font-weight: 600;
  z-index: 100;
  position: relative;
}

.walkcourse_marker:after {
  border-top: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
  top: 21px;
  left: 4.5px;
}

.walkcourse_marker_selected {
  border: 3px solid rgb(38, 129, 255);
  border-radius: 12px;
  padding: 0rem 0.4rem;
  background-color: rgba(38, 129, 255, 0.6);
  color: #ffffff;
  font-weight: 600;
  z-index: 100;
  position: relative;
}

.walkcourse_marker_selected:after {
  border-top: 6px solid rgb(38, 129, 255);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
  top: 21px;
  left: 4.5px;
}

.walkcourse_content_header {
  padding: 1rem 1.2rem;
  font-size: 1rem;
  font-weight: 700;
  background-color: #434655;
  color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.walkcourse_contents_button {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #ebecf0;
}

.walkcourse_contents_index {
  padding: 0.15rem 0.55rem;
  font-size: 1rem;
  border-radius: 12px;
  /* border: 1px solid #cfd8dc; */
  background-color: #e5efff;
  color: #2681ff;
}

.walkcourse_contents_index_selected {
  background-color: #2681ff !important;
  color: #ffffff !important;
}

.walkcourse_contents_time {
  min-width: max-content;
  display: inline-block;
  margin: 2rem 0 0 -1rem !important;
  padding: 0.2rem 0.5rem;
  border: 1px solid #bdbdbd;
  border-radius: 20px;
  background-color: #ffffff;
  color: #66bb6a;
  z-index: 2;
  font-size: 0.8rem;
}

.walkcourse_contents_dashed {
  border-left: 1px dashed #d4d9e1;
  height: 9vh;
  margin-left: 0.8rem;
}

.field_content_marker {
  border: 3px solid #ffffff;
  border-radius: 12px;
  padding: 0rem 0.4rem;
  background-color: #ffeee5;
  color: rgb(255, 116, 38);
  font-weight: 600;
  z-index: 100;
  position: relative;
}

.ml_1rem {
  margin-left: 1rem;
}

.p_1rem {
  padding: 1rem;
}

.m_2rem {
  margin: 2rem;
}

.p_2rem_m_2rem {
  padding: 2rem;
  margin: 2rem 0;
}

.test1 {
  width: 20vw;
  height: 50vh;
  min-width: 300px;
  position: absolute;
  top: 30vh;
  left: 52vw;
}
.test2 {
  width: 20vw;
  height: 50vh;
  min-width: 300px;
  position: absolute;
  top: 30vh;
  left: 923px;
}

.title {
  padding: 0.5rem !important;
  padding-left: 0.15rem !important;
  margin-bottom: 0.33rem !important;
  font-weight: 500 !important;
}

.title_normal {
  padding: 0.5rem !important;
  padding-left: 0.15rem !important;
  margin-bottom: 0.33rem !important;
}

.title_doubleline {
  padding: 0.5rem 0.5rem 0 0.15rem !important;
  font-weight: 500 !important;
}

.chip_container {
  padding: 1rem;
  padding-bottom: 0.5rem;
  margin: 1rem 1.5rem 1rem 0;
  background-color: #fcfcfc !important;
  border: 1px solid #ebecf0;
}

.clear_icon_button {
  font-size: 1rem !important;
  cursor: pointer;
  margin-left: 0.3rem;
}

.user_table_cell {
  padding: 0.3rem 0.6rem !important;
}

.user_table_cell_deleted {
  padding: 0.3rem 0.6rem !important;
  color: #999999 !important;
}
